import hertz_pic from '../media/hertz_pic.jpg';
import grad_pic from '../media/grad_pic.jpg';
import asf_pic from '../media/asf_pic.jpeg';
import freeskate_pic from '../media/freeskate_pic.jpg';
import cra_pic from '../media/cra_pic.jpg';

export const news_items = [
  {
    pic: hertz_pic,
    year: 2024,
    text_p: <p>Awarded the <a href="https://www.hertzfoundation.org/news/hertz-foundation-announces-2024-hertz-fellows/">2024 Hertz Fellowship</a>, which supports the nation's most promising graduate students in science and technology.</p>
  },
  {
    pic: grad_pic,
    year: 2023,
    text_p: <p><a href="https://www.linkedin.com/posts/amanirmk_graduation-classof2023-hmc-activity-7065100005220483072-HtI9?utm_source=share&utm_medium=member_desktop">Graduated from HMC</a> with high distinction and honors, additionally earning the <a href="https://www.hmc.edu/cs/student-awards/">Class of '94 Award</a>, the CS department's top award.</p>
  },
  {
    pic: asf_pic,
    year: 2023,
    text_p: <p>Awarded the <a href="https://www.hmc.edu/about/2022/08/12/harvey-mudd-college-2022-astronaut-scholars/">Astronaut Scholarship</a> by NASA Astronaut and HMC alumnus, <a href="https://www.hmc.edu/calendar/events/astronaut-scholarship-foundation-celebration-with-astronaut-george-pinky-nelson-72/">Pinky Nelson</a>, for initiative, creativity, and excellence in STEM.</p>
  },
  {
    pic: cra_pic,
    year: 2022,
    text_p: <p>Awarded the <a href="https://cra.org/2022-outstanding-undergraduate-researcher-award-recipients/">2022 CRA Outstanding Undergraduate Researcher Award</a> and interviewed for the <a href="https://magazine.hmc.edu/spring-2022/this-kind-of-fun/">Mudd Magazine</a>.</p>
  },
  {
    pic: freeskate_pic,
    year: 2022,
    text_p: <p>Interviewed by the <a href="https://themuddraker.org/2022/02/12/skate-society/">Muddraker</a> on reviving the HMC Skate Society and bringing <a href="https://www.youtube.com/watch?v=QW5MUFjWts8">freeskating</a> back to campus.</p>
  },
];