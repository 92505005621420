import React from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import { name } from '../content/profile_info.js';

const toPagename = (page) => {
  switch (page) {
    case "publications":
      return "Publications";
    case "news":
      return "News";
    default:
      return "Whoops!";
  };
}

const Sidebar = () => {
  const {page} = useParams();
  document.title = (page ? toPagename(page) + " — " : "") + name;
  return (
    <div id="menu-div">
        <h1>{name}</h1>
        <Link to="/" className={page === undefined ? "active-page" : ""}>about</Link>
        <Link to="/publications" className={page === "publications" ? "active-page" : ""}>publications</Link>
        <Link to="/news" className={page === "news" ? "active-page" : ""}>news</Link>
    </div>
  );
}

export default Sidebar;