import amani_pic from '../media/amani.jpg';

export const accent_color = "#A31F34";

export const name = "Amani Maina-Kilaas";

export const profile_pic = amani_pic;

export const introduction = <div>
  <p>I am a second-year PhD student at <a href="https://bcs.mit.edu/academic-program/brain-and-cognitive-sciences-phd-program">MIT Brain & Cognitive Sciences</a>, advised by <a href="https://www.mit.edu/~rplevy/">Roger Levy</a> in the <a href="http://cpl.mit.edu/">Computational Psycholinguistics Lab</a>. I am broadly interested in understanding cognition by studying natural and artificial intelligence in tandem, but I am most excited about developing computational models that accurately reflect human language processing and all of its many nuances. I aspire to use models (in both the scientific and LLM sense) to uncover the principles, constraints, or algorithms driving our acquisition, comprehension, and production of language. My work is supported by a <a href="https://www.hertzfoundation.org/news/hertz-foundation-announces-2024-hertz-fellows/">Hertz Fellowship</a> and a <a href="https://science.mit.edu/about/awards/deans-fellowship-program/">Dean of Science Fellowship</a>.</p>
  <p>I previously earned my Bachelor's with high distinction and departmental honors in Computer Science and Mathematics from <a href="https://www.hmc.edu/about/">Harvey Mudd College</a>, where I worked with <a href="https://www.linkedin.com/in/georgemontanez/">George Monta&ntilde;ez</a> in the <a href="https://www.cs.hmc.edu/~montanez/amistad.html">AMISTAD lab</a>. There, I researched intention perception and theoretical machine learning, receiving the <a href="https://www.hmc.edu/about/2022/08/12/harvey-mudd-college-2022-astronaut-scholars/">Astronaut Scholarship</a> and <a href="https://cra.org/2022-outstanding-undergraduate-researcher-award-recipients/">CRA Outstanding Undergraduate Researcher Award</a>.</p>
</div>;

// icons from fontawesome 6.1.1 (free), or academicons (https://jpswalsh.github.io/academicons/)
export const icons = [
  {
    className: "ai ai-cv",
    link: process.env.PUBLIC_URL + '/maina-kilaas_cv.pdf'
  },
  {
    className: "ai ai-semantic-scholar",
    link: "https://www.semanticscholar.org/author/Amani-Rune-Maina-Kilaas/2051872161"
  },
  {
    className: "ai ai-google-scholar",
    link: "https://scholar.google.com/citations?user=46I8yggAAAAJ"
  },
  {
    className: "fab fa-linkedin-in",
    link: "https://www.linkedin.com/in/amanirmk/"
  },
  {
    className: "fa-brands fa-x-twitter",
    link: "https://twitter.com/amanirmk"
  },
  {
    svg: 
      <svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M351.121 315.106C416.241 363.994 486.281 463.123 512 516.315C537.719 463.123 607.759 363.994 672.879 315.106C719.866 279.83 796 252.536 796 339.388C796 356.734 786.055 485.101 780.222 505.943C759.947 578.396 686.067 596.876 620.347 585.691C735.222 605.242 764.444 670.002 701.333 734.762C581.473 857.754 529.061 703.903 515.631 664.481C513.169 657.254 512.017 653.873 512 656.748C511.983 653.873 510.831 657.254 508.369 664.481C494.939 703.903 442.527 857.754 322.667 734.762C259.556 670.002 288.778 605.242 403.653 585.691C337.933 596.876 264.053 578.396 243.778 505.943C237.945 485.101 228 356.734 228 339.388C228 252.536 304.134 279.83 351.121 315.106Z" fill="#1185FE"/>
      </svg>
    ,    
    link: "https://bsky.app/profile/amanirmk.com"
  },
  {
    className: "fas fa-paper-plane",
    link: "mailto:amanirmk@mit.edu"
  }
];